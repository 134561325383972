    
    .css-16objhc-MuiGrid-root {
        align-self: center;
        /*background-color: #F3F3F3 !important;*/
        height: 24%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .App {
        text-align: center;
        background-color: #F3F3F3;
    }