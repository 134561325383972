.footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #707070;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 58.4%;
    /*top: 100vh;*/

}

.footer-full{
    width: 100%;
}

.footer a {
    flex-basis: 30%;
    text-align: center;
    padding: 10px;
}

.footer img {
    height: 4rem;
    object-fit: contain;
}

.smaller {
    height: 40px !important;
}